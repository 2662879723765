<template>
  <div class="sidebar-index fondo-sidebar d-flex flex-wrap align-items-stretch">
    <div class="d-flex flex-column justify-content-center w-100">
      <img :src="hasLogo" srcset="" class="w-100" />
    </div>
    <div v-if="National?.fgNational" class="w-100">
      <div class="d-flex justify-content-center align-items-center">
        <CButton
          color="edit"
          class="mb-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top-start',
          }"
          size="sm"
          @click="$emit('nationalReturn')"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
      </div>
    </div>
    <div
      class="fullscreen-option w-100"
      :class="{ active: !National?.fgNational ? (fgFullscreen ? true : null) : (National.FgFullScreen ? true : null) }"
      @click="fullscreen"
    >
      <img
        src="/img/weather/fullscreen-svgrepo-com.svg"
        alt="anchor"
        srcset=""
        class="fullscreen-icon"
      />
      <h4 class="berth-options-title">FULLSCREEN</h4>
    </div>
    <div class="d-flex flex-column justify-content-center w-100">
      <div
        class="fullscreen-option"
        @click="$emit('intervalSidebar', !FgIntervalSidebar)"
      >
        <img
          src="/img/iconos/pause-svgrepo-com.svg"
          alt="anchor"
          srcset=""
          class="fullscreen-icon"
          v-if="FgIntervalSidebar"
        />
        <img
          src="/img/iconos/play-svgrepo-com.svg"
          alt="anchor"
          srcset=""
          class="fullscreen-icon"
          v-else
        />
      </div>
    </div>
    <div class="w-100">
      <div class="d-flex justify-content-center m-0 w-100 px-1">
        <img
          src="/img/weather/anchorRecurso4.svg"
          alt="anchor"
          srcset=""
          class="anchor-icon"
        />
      </div>
      <h4 class="berth-options-title w-100 px-1">{{$t('label.puestoDeAtraque')}}</h4>
      <div class="w-100 px-1">
        <div class="box-options w-100 px-1">
          <ul class="berth-options">
            <li
              v-for="(item) in berthsPort"
              @click="$emit('zoomToBerth', item.BerthName)"
              :class="{ active: (selected?.BerthName == item.BerthName) ? true : null, desactive: item.VisitJson.length == 0 ? true : null}"
            >
              {{ item.BerthName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="sidebar-weather w-100">
      <WeatherApp :latitude="Number(branch.LatitudeCompanyBranch)" :longitude="Number(branch.LongitudeCompanyBranch)" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import WeatherApp from "@/components/weather/WeatherApp";

function data() {
  return {
    fgFullscreen: false,
    isPlaying: false,
    berthsPort: []
  };
}

//method
function fullscreen() {
  if (!this.National?.fgNational) {
    if (this.fgFullscreen) {
      document.exitFullscreen();
    }else {
      document.getElementById("GuairaSituation").requestFullscreen();
    }
    this.fgFullscreen = !this.fgFullscreen;
  } else {
    if (this.National?.FgFullScreen) {
      document.exitFullscreen();
    }else {
      document.getElementById("nationalSituation").requestFullscreen();
    }
    this.$emit('FgFullScreenNational', !this.National.FgFullScreen)
  }
}

//computed
function hasLogo() {
  return `/img/weather/logoWhite.png`;
}

export default {
  name: "sidebar-index",
  components: {
    WeatherApp
  },
  data,
  props: {
    selected: null,
    berths: {
      type: Array,
      default: () => [],
    },
    National: {
      type: Object,
      default: () => {},
    },
    FgIntervalSidebar: Boolean,
  },
  methods: {
    fullscreen
  },
  computed: {
    hasLogo,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
  },
  watch:{
    berths: function (val) {
      this.berthsPort = val;
    },
  }
};
</script>
<style scoped>

  .box-options{
    height: 150px;
    overflow-y: auto;
  }
 .box-options::-webkit-scrollbar {
    height: 8px;
    width: 6px;
  }
  .box-options::-webkit-scrollbar-track {
    background-color: #393c3f; 
  }
  .box-options::-webkit-scrollbar-thumb {
    background-color: #52a3a6; 
  }
  .sidebar-index {
    width: 200px;
    min-width: 200px;
  }
  .fondo-sidebar {
    background: #4c5566 url("/img/weather/sidebar.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .fullscreen-option {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .fullscreen-option > h4 {
    color: #fff;
    transition: color 0.5s ease;
  }
  .fullscreen-option:hover > img,
  .fullscreen-option.active > img {
    filter: invert(74%) sepia(6%) saturate(3297%) hue-rotate(328deg)
      brightness(104%) contrast(84%);
  }
  .fullscreen-option:hover > h4,
  .fullscreen-option.active > h4 {
    color: #eba784;
  }
  .fullscreen-icon {
    width: 22px;
    margin: 6px;
    filter: invert(100%) sepia(0%) saturate(7476%) hue-rotate(110deg)
      brightness(98%) contrast(108%);
    transition: filter 0.5s ease;
  }
  .berth-options-title {
    color: #fff;
    text-align: center;
    font-size: 1rem;
  }
  .berth-options {
    list-style: none;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #fff;
    padding: 0;
    margin: 0;
  }
  .berth-options > li {
    cursor: pointer;
    color: #fff;
    transition: color 0.5s ease;
  }
  .berth-options > li:last-child {
    padding-bottom: 5px;
  }
  .berth-options > li:hover,
  .berth-options > li.active {
    color: #eba784;
  }
  .berth-options > li.desactive {
    opacity: 0.5;
  }
  .anchor-icon {
    width: 22px;
    margin: 6px;
    filter: invert(100%) sepia(0%) saturate(7476%) hue-rotate(110deg)
      brightness(98%) contrast(108%);
  }

  .sidebar-weather {
    width: 100%;
    margin-top: auto;
  }
  @media (min-height: 864px) {
  }
</style>